<template>
    <div ref="modal" class="modal fade" id="modal_notif_confirm" tabindex="-1" aria-labelledby="modal_reviewLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-review-content">
                <div class="modal-body">
                    <div class="head-mod-container">
                        <h3 class="head-modal-title">
                        Review
                        </h3>
                        <p class="des-modal">
                            The following are confirmed to be members of <br>
                            <b>Church Name</b>.
                        </p>
                    </div>
                    <div class="user_list_container">
                        <ul>
                            <li v-for="(item, index) in data" :key="index">{{ item.fname }} {{ item.lname }}</li>
                        </ul>
                    </div>
                    <div class="d-grid gap-2">
                        <button type="button" class="btn btn-submit-review" @click="confirm">Confirm</button>
                        <button type="button" class="btn btn-cancel-modal" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex';

export default defineComponent({
    props: {
        data: Array
    },
    setup() {
        
    },
    methods: {
        async confirm() {
            // await Promise.all(this.data.map(async i => {
            //     await this.updateChurchMember([i.id, { status: 'Confirmed' }]);
            // }));
            // window['$'](this.$refs['modal']).modal('hide');
            // this.$emit('complete', true);
            await Promise.all(this.data.map(async i => {
                await this.apiPatch([
                    i.is_church === 1 ? 'confirm-church' : 'confirm-user',
                    {
                        id: i.id
                    }
                ]);
            }));
            window['$'](this.$refs['modal']).modal('hide');
            this.$emit('complete', true);
        },
        ...mapActions('apis', { apiPatch: 'patch' }),
        ...mapActions('user-church', { updateChurchMember: 'patch' })
    }
})
</script>


