<template>
    <section class="confirm-members-section">
        <div class="container my-container">
            <div class="church-members-content">
                <div class="row no-gutters">
                    <div class="col-sm-12">
                        <h4>Confirm Members</h4>
                    </div>

                    <div class="container-text">
                        <h5>A good day to confirm CHURCH members.</h5>
                        <p>Please take a moment,<br> pray and ask the Holy Spirit to guide you as you confirm members of your church,<br> and as we keep the integrity of our organization.
                        </p>
                    </div>
                </div>
                <div class="table-container">
                    <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th scope="col"><input type="checkbox" @change="onMarkAll" class="form-check-input" id="exampleCheck1"></th>
                            <th scope="col"></th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in unverifiedUsers" :key="`${index}-${refresh}`">
                            <td scope="row"><input type="checkbox" v-model="itemLists[item.id].model" class="form-check-input" id="exampleCheck1"></td>
                            <td><img :src="item.avatar" alt="" class="usr_prof_container img-fluid"></td>
                            <td colspan="2">{{ item.fname }} {{ item.lname }}</td>
                            <td>{{ item.email }}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>

                <div class="d-grid gap-2">
                    <button type="button" class="btn btn-submit-confirm" data-bs-toggle="modal" data-bs-target="#modal_notif_confirm" :disabled="buttonSubmitEnable">Submit</button>
                </div>
            </div>
        </div>
    </section>
    <ModalConfirmMembers :key="`model-${refresh}`" :data="generatedData" @complete='formComplete' />
</template>

<script>
import { useFind } from '@feathersjs/vuex';
import { computed, defineComponent, getCurrentInstance, ref } from 'vue';
import { /*mapActions,*/ mapGetters } from 'vuex';
import ModalConfirmMembers from '../components/modals.component/modal-confirm-members.vue';

export default defineComponent({
    setup() {
        const vm = getCurrentInstance();
        const user = vm.appContext.provides.store.state.auth.user;
        const { Church, /*VWUsersChurch,*/ VWUnverifiedUsers, UserVerified }  = vm.appContext.provides.$FeathersVuex.api;
        const currentUser = vm.appContext.provides.store.state.auth.user;

        const itemValues = {};
        const refresh = ref(new Date().getTime() / 1000);
        
        const church = useFind({ model: Church, params: computed(() => {
            return { query: { owner_id: currentUser.id } };
        }), fetchParams: computed(() => {
            return { query: { owner_id: currentUser.id } };
        }), immediate: true });

        const verifiedLists = useFind({
            model: UserVerified,
            params: computed(() => {
                return { query: { member_id: user.id } }
            }),
            fetchParams: computed(() => {
                return { query: { member_id: user.id, $paginate: false } }
            }),
            immediate: true 
        });

        const queryUnverifiedUsers = computed(() => {
            return { query: {
                id: { $nin: [ user.id, ...verifiedLists.items.value.map(i => i.user_id) ] },
                church_id: church.items.value.length !== 0 ? church.items.value[0].id : 0,
            } };
        });
        const unverifiedUsers = useFind({
            model: VWUnverifiedUsers,
            params: queryUnverifiedUsers,
            fetchParams: queryUnverifiedUsers
        });
 
        // const userChurch = useFind({
        //     model: VWUsersChurch,
        //     params: computed(() => {
        //         return { 
        //             query: {
        //                 church_id: church.items.value.length !== 0 ? church.items.value[0].id : 0,
        //                 status: 'Unconfirmed'
        //             }
        //         };
        //     }),
        //     fetchParams: computed(() => {
        //         return { 
        //             query: {
        //                 church_id: church.items.value.length !== 0 ? church.items.value[0].id : 0,
        //                 status: 'Unconfirmed',
        //                 $paginate: false
        //             }
        //         };
        //     })
        // });

        const itemLists = computed(() => {
            return unverifiedUsers.items.value.reduce((result, item) => {
                itemValues[item.id]
                result[item.id] = {
                    set model(v) {
                        itemValues[item.id] = v;
                        refresh.value = new Date().getTime() / 1000;
                    },
                    get model() {
                        return itemValues[item.id];
                    }
                };
                return result;
            }, {});
        });

        return {
            unverifiedUsers: unverifiedUsers.items,
            // userChurch: userChurch.items,
            itemLists,
            refresh
        };
    },
    computed: {
        buttonSubmitEnable() {
            return this.unverifiedUsers.length === 0 || this.generatedData.length === 0;
        },
        generatedData() {
            const refresh = this.refresh;
            return Object.keys(this.itemLists).filter(i => this.itemLists[i].model).map(i => ({
                ...this.getItem(parseInt(i)),
                refresh
            }));
        },
        ...mapGetters('vw-unverified-users', { getItem: 'get' })
    },
    methods: {
        onMarkAll(e) {
            if(e.target.checked) {
                Object.keys(this.itemLists).map(k => {
                    this.itemLists[k].model = true;
                });
            } else {
                Object.keys(this.itemLists).map(k => {
                    this.itemLists[k].model = false;
                });
            }
            this.refresh = new Date().getTime() / 1000;
        },
        formComplete() {
            // this.generatedData.map(i => {
            //     this.getNewData(i.id);
            // });
            console.log('completed');
        },
        // ...mapActions('vw-users-church', { getNewData: 'get' })
    },
    components: {
        ModalConfirmMembers
    }
})
</script>
